<template>
  <div>
    <h3 ref="ia">
      Square Formula
    </h3>
    <p>
      The square formula states that the square of the sum of two numbers \(a\) and \(b\)
      is equal to the sum of the squares of the two numbers and twice of their product.
      $$(a+b)^2 = a^2 + 2ab + b^2$$
    </p>
    <br>
    <h3 id="mgk" ref="playgraph">
      MagicGraph | Square Formula
    </h3>
    <p>
      This MagicGraph offers a visually interactive way to derive the square formula.
      You are given a square with arm length \(a+b\). The area of the square is equal to \((a+b)^2\).
    </p><h5> To Get Started: </h5>
    <p>
      Now, divide each side of the square into two segments of length \(a\) and \(b\).
      That way - the square ABCD can be divided into a green square (has an area = \(a^2\)), a blue square (has an area = \(b^2\)), and two red rectangles (each has an area = \(ab\)). <br>
      $$
      \text{Area of square ABCD} =  \text{Area of the green square} + \text{Area of the blue square} + \text{2 x Area of the red square}. $$
      <br>
      Thus, we have
      $$(a+b)^2 = a^2 + b^2 + 2 a b$$
    </p>
    <h5> To Explore:</h5>
    <p>
      You can vary the values of \(a\) and \(b\) by moving the point labeled "Drag me!".
      Note that the sum of the \(a\) and \(b\) remains constant, i.e., \(a+ b =4\).
      For any values of \(a\) and \(b\) you choose, you can verify that the sum given by \(a^2+ b^2+ 2ab\) is always equal to \((a+b)^2\).
    </p>

    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'SquareFormula',
  created: function () {
    // Store mutations
    this.$store.commit('navigation/resetState');
    let title = 'Square Formula';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Square Formula', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo: {
          title: 'Square Formula',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about square formula'}
                ]
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
